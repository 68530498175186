import {
  ApplicationInsights,
  SeverityLevel
} from '@microsoft/applicationinsights-web';

interface AzureAppInsightsConfig {
  connectionString: string;
  disableAjaxTracking?: boolean;
  disableFetchTracking?: boolean;
  disableSessionTracking?: boolean;
  disableExceptionTracking?: boolean;
  disableTelemetry?: boolean;
  disablePerformanceTracking?: boolean;
  disableDependencyTracking?: boolean;
  disableAppId?: boolean;
  disableDataLossAnalysis?: boolean;
}

let appInsights: AppInsightsSdk | undefined = undefined;

/** Access Azure Application Insights SDK. */
export const useAzureAppInsights = () => {
  appInsights ??= createAzureAppInsights({
    connectionString: import.meta.env.VITE_AZURE_APP_INSIGHTS_CONNECTION_STRING,
    disableAjaxTracking: true,
    disableFetchTracking: true,
    disableSessionTracking: true,
    disableExceptionTracking: false,
    disableTelemetry: false,
    disablePerformanceTracking: true,
    disableDependencyTracking: true,
    disableAppId: true,
    disableDataLossAnalysis: true
  });
  return appInsights;
};

/** The Azure Application Insights SDK object that will be used by the public */
export type AppInsightsSdk = ReturnType<typeof createAzureAppInsights>;

/**
 * Create a new Azure Application Insights instance. The returned object will be used by packages importing this.
 *
 * @param appInsightsConfig Configuration of Azure Application Insights.
 */
const createAzureAppInsights = (appInsightsConfig: AzureAppInsightsConfig) => {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: appInsightsConfig.connectionString
    }
  });
  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer(t => {
    // Update criteria as per your need.
    if (t.baseType == 'PageView') return false; // disable
    return true; // enable everything else
  });

  return {
    /**
     * Log a custom event in Azure Application Insights.
     *
     * @param {string} name - Descriptive name given to the event.
     * @param {Record<string, any>} [properties] - Optional properties to pass along with the event to the logs.
     *
     * Query to see these logs in Azure Application Insights should begin with `customEvents | where name == "nameOfEvent"`.
     */
    logEvent: (name: string, properties?: { [key: string]: any }) => {
      appInsights.trackEvent({
        name: name,
        properties: properties
      });
    },

    /**
     * Log an error object in Azure Application Insights.
     *
     * @param {Error} error - The error to log.
     * @param {string} source - Descriptive phrase (or path to file) representing from where the error is being logged.
     * @param {Record<string, any>} [properties] - Optional additional properties to include with the exception telemetry.
     * @param {SeverityLevel} [severityLevel] - Optional severity level of the exception. If not provided, defaults to `SeverityLevel.Error`.
     * See [SeverityLevel documentation](https://learn.microsoft.com/en-us/dotnet/api/microsoft.applicationinsights.datacontracts.severitylevel?view=azure-dotnet) for allowed values.
     *
     * Query to see these logs in Azure Application Insights should begin with `exceptions | where customDimensions.Source == "sourceParameter"`.
     */
    logError: (
      error: Error,
      source: string,
      properties?: { [key: string]: any },
      severityLevel?: SeverityLevel
    ) => {
      if (!properties) properties = {};
      properties.ErrorLoggedByFE = true;
      properties.Source = source;
      appInsights.trackException({
        exception: error,
        properties: properties,
        severityLevel: severityLevel ?? SeverityLevel.Error
      });
    }
  };
};
