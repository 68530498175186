import { base64Encode } from '@/shared/global-functions';
import { type AuthGuardData } from '@/router/guards';
import { ROUTES } from '@/shared/constants';
import { useProfileStore } from '@/stores/profile';
import type { MemberType } from '@/types/profile';
import { AxiosError, HttpStatusCode } from 'axios';

/** Header that is present when a 401 is returned from our custom authorization attribute */
const customAuthHeader = 'x-sqlauthorization-failure';

/**
 * HTTP interceptor that checks a response for a 401 HTTP Status code.
 * The interceptor then checks if the `x-sqlauthorization-failure` header is present.
 * If the header is present, tries to redirect the user to a login screen.
 */
export const authInterceptor = (err: unknown) => {
  if (
    err instanceof AxiosError && // We only care about axios errors
    err.response?.status === HttpStatusCode.Unauthorized && // Status code must be unauthorized
    err.response?.headers[customAuthHeader] != null // Our custom authorization failure header must be present
  ) {
    // The only time the custom header returns a non-empty string is when it is returning the type of member required
    const header = err.response?.headers[customAuthHeader] as MemberType | '';
    if (header.length > 0) {
      const memberType = header as MemberType;
      const profileStore = useProfileStore();
      const { isLoggedIn } = profileStore;

      // Automatically redirect to login if user is not logged in
      if (!isLoggedIn) {
        const url = profileStore.generateLoginUrl(
          memberType,
          window.location.pathname
        );
        window.location.href = url;
      } else {
        // Otherwise, redirect to unauthorized route
        const authData: AuthGuardData = {
          memberType: {
            key: memberType[0].toUpperCase() + memberType.substring(1),
            value: memberType
          },
          returnUrl: window.location.pathname
        };
        const base64Data = base64Encode(authData);
        const url =
          ROUTES.UNAUTHORIZED + `?data=${encodeURIComponent(base64Data)}`;
        window.location.href = url;
      }
    }
  }

  return Promise.reject(err);
};
