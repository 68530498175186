// NOTE: When using the sticky column feature, add a transition to the element to prevent jankiness.

interface HTMLElementWithCleanup extends HTMLElement {
  _onScrollCleanup?: () => void; // Optional cleanup function to remove event listeners.
}

interface ScrollyBinding {
  watchEl: string; // Selector for the element that the scrolling element should align with.
}

const ScrollyWatchDirective = {
  mounted(el: HTMLElementWithCleanup, binding: { value: ScrollyBinding }) {
    // Throttle function limits the execution rate of the passed function.
    // Used here to prevent excessive function calls during scrolling or resizing.
    const throttle = (func: Function, limit: number) => {
      let lastFunc: any;
      let lastRan: number;
      return function (...args: any[]) {
        if (!lastRan) {
          func(...args); // Run the function immediately the first time.
          lastRan = Date.now();
        } else {
          clearTimeout(lastFunc); // Clear any previously queued function calls.
          lastFunc = setTimeout(() => {
            if (Date.now() - lastRan >= limit) {
              func(...args); // Only run the function if the throttle limit has passed.
              lastRan = Date.now();
            }
          }, limit - (Date.now() - lastRan));
        }
      };
    };

    // Locate the element the scrolling element should align with.
    const watchEl = document.querySelector(
      binding.value.watchEl
    ) as HTMLElement;

    // If the watch element is not found, log a warning and exit the function.
    if (!watchEl) {
      console.warn(
        `Element with selector "${binding.value.watchEl}" not found.`
      );
      return;
    }

    // Capture the initial top position of the scrolling element for reference.
    const initialElTop = el.offsetTop;

    // Function to update the `margin-top` of the scrolling element based on the scroll position.
    const updateElementPosition = () => {
      const elBounds = el.getBoundingClientRect(); // Get the element's current position in the viewport.
      const scrollY = window.scrollY; // Current scroll position.

      // Calculate the bottom position of the `watchEl` relative to the document.
      const watchElBottomFromViewport =
        watchEl.offsetTop + watchEl.offsetHeight;

      // Calculate the maximum scroll distance before the element's bottom aligns with the `watchEl`'s bottom.
      const maxScrollDistance = watchElBottomFromViewport - el.offsetHeight;

      // Adjust the `margin-top` to "stick" the element at the top of the viewport, but stop once the bottoms align.
      if (scrollY >= initialElTop) {
        let newMarginTop = Math.min(
          scrollY - initialElTop,
          maxScrollDistance - initialElTop
        );
        newMarginTop = newMarginTop < 0 ? 0 : newMarginTop; // Ensure margin is never negative.
        el.style.marginTop = `${newMarginTop}px`;
      } else {
        el.style.marginTop = ''; // Reset margin if the element is back at the top of the page.
      }
    };

    // Throttle the scroll and interaction handlers to prevent too many calls during user activity.
    const handleScroll = throttle(updateElementPosition, 50); // Called during scrolling.
    const handleInteraction = throttle(updateElementPosition, 100); // Called during other interactions (e.g., resizing, clicking).

    // Attach event listeners for scroll, resize, and interaction events (click, input, change).
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleInteraction);
    document.addEventListener('click', handleInteraction);
    document.addEventListener('input', handleInteraction);
    document.addEventListener('change', handleInteraction);

    // Initial update to ensure the correct position on page load.
    updateElementPosition();

    // Store a cleanup function on the element to remove event listeners when the element is unmounted.
    el._onScrollCleanup = () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleInteraction);
      document.removeEventListener('click', handleInteraction);
      document.removeEventListener('input', handleInteraction);
      document.removeEventListener('change', handleInteraction);
    };
  },

  // Unmount lifecycle hook, ensures event listeners are cleaned up when the element is removed from the DOM.
  unmounted(el: HTMLElementWithCleanup) {
    if (el._onScrollCleanup) el._onScrollCleanup(); // Call the cleanup function to remove event listeners.
  }
};

export default ScrollyWatchDirective;
