import apiClient, { type ApiResponse } from '@/shared/api-client';

const featureFlagService = {
  /**
   * Checks the status of a feature flag
   * @returns boolean
   */
  async isEnabled(flagName: string): Promise<boolean> {
    const res: ApiResponse<boolean> = await apiClient.get(
      `/api/FeatureFlag/IsEnabled?flagName=${flagName}`
    );
    return res.data;
  }
};

export default featureFlagService;
