import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';
import apiClient from '@/shared/api-client';
import type { LoggedInRes } from '@/types';
import type {
  Profile,
  FavLoc,
  UpdateProfilePayload,
  MemberType
} from '@/types/profile';
import { exists } from '@/shared/global-functions';

export const useProfileStore = defineStore('profileStore', () => {
  const profile = ref<Profile | undefined>(undefined);
  const isLoggedIn = ref(false);
  const useFocus = ref<boolean>(false);

  // #region TOAST MESSAGE
  interface ToastType {
    show: boolean;
    message: string | null;
    isWarning: boolean | undefined;
    quick: boolean | undefined;
  }
  const toastMessage = ref<ToastType>({
    show: false,
    message: null,
    isWarning: false,
    quick: false
  });
  const toastMessageShow = (
    message: string,
    isWarning?: boolean,
    quick?: boolean
  ) => {
    toastMessage.value.show = true;
    toastMessage.value.message = message;
    toastMessage.value.isWarning = isWarning;
    toastMessage.value.quick = quick;
  };
  const toastMessageHide = () => {
    toastMessage.value.show = false;
    toastMessage.value.message = null;
    toastMessage.value.isWarning = false;
    toastMessage.value.quick = false;
  };
  // #endregion TOAST MESSAGE

  const checkLoginAsync = async () => {
    const res = await apiClient
      .get<LoggedInRes>('/api/Login/IsLoggedIn')
      .catch(() => ({ data: { isLoggedIn: false } }));
    if (!res.data.isLoggedIn) {
      profile.value = undefined;
      isLoggedIn.value = false;
    } else {
      isLoggedIn.value = true;
    }
    return res.data.isLoggedIn;
  };

  const formatAndSetProfile = (data: Profile) => {
    profile.value = {
      ...data,
      fullName:
        `${data.firstName} ${data.middleName} ${data.lastName} ${data.suffix}`
          .replace(/\s+/g, ' ')
          .trim(),
      lastFirstName:
        `${data.lastName}, ${data.firstName} ${data.middleName} ${data.suffix}`
          .replace(/\s+/g, ' ')
          .trim()
    };
  };

  const getProfileAsync = async (forceRefresh?: boolean) => {
    if (!isLoggedIn.value) {
      profile.value = undefined;
      return profile;
    }

    if (profile.value == null || forceRefresh) {
      const res = await apiClient
        .get<Profile>('/api/Profile/GetProfile/')
        .catch(err => {
          console.error(err);
          return { data: undefined };
        });

      if (res.data) {
        formatAndSetProfile(res.data);
      } else {
        profile.value = undefined;
      }
    }

    return profile;
  };

  const updateProfileAsync = async (payload: UpdateProfilePayload) => {
    const patientUrl = '/api/Profile/UpdatePatientProfile';
    const providerUrl = '/api/Profile/UpdateProviderProfile';
    const apiUrl =
      profile.value?.memberType === 'patient' ? patientUrl : providerUrl;
    try {
      const res = await apiClient.post<Profile>(apiUrl, payload);
      if (res.data == null) {
        console.warn(
          'Unable to set updated profile data. The API returned null data'
        );
        return undefined;
      }
      toastMessageShow(
        `
          <h2>Success!</h2>
          <p>Your profile has been updated.</p>
        `,
        false,
        true
      );
      formatAndSetProfile(res.data);
      return profile;
    } catch (err) {
      console.error('Error Updating Profile:', err);
      toastMessageShow(
        `
          <h2>We're sorry...</h2>
          <p>An unexpected error has occurred. Please try again later.</p>
        `,
        true
      );
      return undefined;
    }
  };

  const favLocationToggle = async (favLoc: FavLoc) => {
    const favs = profile.value?.favoriteLocations || [];
    const hasThreeFavs = favs.length === 3;
    const existingFav = favs.find(f => f.locationCode === favLoc.locationCode);
    if (!existingFav && hasThreeFavs) {
      toastMessageShow('You can only have 3 favorite locations.');
      return;
    }

    const apiUrl = '/api/Profile/UpdatePatientFavLocation/';
    const isAddingFav = !existingFav;
    const payload = {
      code: favLoc.locationCode,
      isAdd: isAddingFav
    };
    try {
      const res = await apiClient.post<FavLoc[]>(apiUrl, payload);
      profile.value!.favoriteLocations = res.data;
    } catch (err) {
      console.error('/api/Profile/UpdatePatientFavLocation/...', err);
      toastMessageShow(
        'Unexpected error adding a favorite location. Please try again later.',
        true
      );
    }
  };

  const setFocus = (focus: boolean) => {
    useFocus.value = focus;
  };

  const setGetlabsId = (getlabsId: string) => {
    if (profile.value) {
      profile.value.getlabsAccountId = getlabsId;
    }
  };

  const router = useRouter();

  const generateLoginUrl = (
    memberType: MemberType | null = null,
    returnUrl: string | null = null,
    prompt: string | null = null
  ): string => {
    const hasMemberType = memberType !== null;
    const hasProfileMemberType = exists(profile.value?.memberType);

    if (!hasMemberType && (!isLoggedIn.value || !hasProfileMemberType))
      throw new Error('User must be logged in to generate a login URL');
    else if (!hasMemberType && hasProfileMemberType)
      memberType = profile.value!.memberType as MemberType;

    const isPatient = memberType === 'patient';

    if (!exists(returnUrl)) returnUrl = router.currentRoute.value.fullPath;
    const encodedReturnUrl = encodeURIComponent(returnUrl as string);

    let loginUrl = `/api/login/externallogin?returnUrl=${encodedReturnUrl}&ispatient=${isPatient}`;

    if (prompt) loginUrl += `&prompt=${prompt}`;

    return loginUrl;
  };

  const toggleFavProduct = async (sku: string, add: boolean) => {
    if (profile.value?.memberType === 'patient') {
      await apiClient.post('/api/profile/UpdatePatientFavProduct', {
        code: sku,
        isAdd: add
      });

      if (add)
        profile.value?.favoriteProducts?.push({ id: 0, productCode: sku });
      else {
        const index = profile.value?.favoriteProducts?.findIndex(
          x => x.productCode == sku
        );

        if (index == undefined)
          throw "Product was not found in list of patient's favorites";

        if (index > -1) {
          profile.value?.favoriteProducts?.splice(index, 1);
        }
      }
    } else {
      const res = await apiClient.post(
        '/api/profile/UpdateProviderFavProduct',
        { code: sku, isAdd: add }
      );
      profile.value!.favoriteProducts = res.data;
    }
  };

  const attest = async () => {
    profile.value!.providerMustAttestForSalesforceEC = false;
    return;
  };

  const logout = () => {
    sessionStorage.clear();
    window.location.href = '/api/login/logout/';
  };

  // Watcher to update isLoggedIn based on profile changes
  watch(profile, newProfile => {
    isLoggedIn.value = exists(newProfile);
  });

  return {
    profile,
    isLoggedIn,
    toastMessage,
    useFocus,
    formatAndSetProfile,
    toastMessageShow,
    toastMessageHide,
    setFocus,
    checkLoginAsync,
    getProfileAsync,
    updateProfileAsync,
    favLocationToggle,
    setGetlabsId,
    generateLoginUrl,
    toggleFavProduct,
    attest,
    logout
  };
});
