import { nextTick, type Ref } from 'vue';
import apiClient from '@/shared/api-client';

// #region SCROLL TO TOP
/**
 * Scrolls the window to the top with specified scroll behavior.
 *
 * @param behavior The scroll behavior ('auto', 'smooth', or 'instant'). Default is smooth.
 * @param position The position of the scroll. Contains a `top` and `left` property. Default is 0 for both.
 * @returns A promise that resolves once the scroll action is complete.
 */
export const scrollToTopAsync = async (
  behavior?: ScrollBehavior,
  position: Omit<ScrollToOptions, 'behavior'> = { top: 0, left: 0 }
): Promise<void> => {
  const scrollBehavior = behavior ?? 'smooth';
  const top = position.top ?? 0;
  const left = position.left ?? 0;
  await nextTick();
  window.scrollTo({
    top: top,
    left: left,
    behavior: scrollBehavior
  });
};
// #endregion SCROLL TO TOP

// #region SCROLL TO ELEMENT
/**
 * Scrolls an element into view with specified behavior and block options.
 * @param el The element or ref to the element to scroll into view.
 * @param behavior The scroll behavior ('auto' or 'smooth'). Default is smooth.
 * @param block The scroll alignment ('start', 'center', 'end', or 'nearest'). Default is start.
 */
export const scrollIntoView = (
  el: Element | Ref<Element> | null,
  behavior: 'auto' | 'smooth' = 'smooth',
  block: ScrollLogicalPosition = 'start'
): void => {
  // Check if el is a direct Element
  if (el instanceof Element) {
    el.scrollIntoView({
      behavior: behavior,
      block: block
    });
  }
  // Check if el is a Ref containing an Element
  else if (el && 'value' in el && el.value instanceof Element) {
    el.value.scrollIntoView({
      behavior: behavior,
      block: block
    });
  }
  // If el is null or not an Element or Ref, do nothing
};

/**
 * Finds the first element with the classname 'warning' and scrolls that element into view.
 */
export const scrollToFirstError = (): void => {
  const errorFields = document.getElementsByClassName('warning');
  if (!errorFields.length) return;
  const firstField = errorFields[0];
  const el = firstField as HTMLElement;
  if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' });
};
// #endregion SCROLL TO ELEMENT

// #region TRUNCATE TEXT
/**
 * Truncates a string to the specified maximum length, adding "..." if the string is longer.
 *
 * @param input The string to truncate.
 * @param maxLength The maximum length of the truncated string.
 * @returns The truncated string with "..." appended if truncation occurs.
 */
export const truncateString = (input: string, maxLength: number): string => {
  if (input.length > maxLength) {
    return input.substring(0, maxLength) + '...';
  } else {
    return input;
  }
};
// #endregion TRUNCATE TEXT

// #region NUMBER FORMATTING UTILITIES
/**
 * Formats a number as a currency string in USD.
 *
 * @param amount The numeric value to format.
 * @returns The formatted currency string in USD.
 */
export const formatAsUSD = (amount: number): string => {
  if (isNaN(amount)) {
    return '$0.00';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return formatter.format(amount);
};
// #endregion NUMBER FORMATTING UTILITIES

// #region URL PARAMS
/**
 * Retrieves the value of a URL parameter by key.
 *
 * @param key The name of the URL parameter to retrieve.
 * @returns The value of the parameter, or null if the parameter does not exist.
 */
export const getUrlParamValue = (key: string): string | null => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(key);
};

/**
 * Deletes a URL parameter by key and updates the browser's history state.
 *
 * @param key The name of the URL parameter to delete.
 */
export const deleteUrlParam = (key: string): void => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.delete(key);
  if (params.size > 0) {
    window.history.replaceState(
      null,
      document.title,
      `${window.location.pathname}?${params.toString()}`
    );
  } else {
    window.history.replaceState(
      null,
      document.title,
      `${window.location.pathname}`
    );
  }
};
// #endregion URL PARAMS

// #region BASE64 ENCODE
/**
 * Encodes data into a base64 string.
 *
 * @param data Data to encode.
 * @returns Base64 encoded string or an empty string if no data is provided.
 */
export const base64Encode = <T>(data: T): string => {
  if (typeof data === 'string' && data.length > 0) {
    return btoa(data);
  } else if (
    typeof data === 'boolean' ||
    typeof data === 'number' ||
    typeof data === 'function'
  ) {
    return base64Encode(data.toString());
  } else if (data != null) {
    return base64Encode(JSON.stringify(data));
  }
  return '';
};

/**
 * Decodes a base64 encoded string.
 *
 * @param base64 Base64 string to decode.
 * @returns Decoded base64 string. If the base64 string is empty, returns undefined.
 */
export const base64Decode = (base64: string): string | undefined => {
  if (base64.length <= 0) return undefined;
  return atob(base64);
};

/**
 * Decodes a base64 string that contains a JSON object.
 *
 * @param base64 Base64 string to decode.
 * @returns Decoded JSON object, or undefined if decoding fails.
 */
export const base64DecodeJson = <T>(base64: string): T | undefined => {
  const decoded = base64Decode(base64);
  if (decoded == null) return undefined;
  try {
    return JSON.parse(decoded) as T;
  } catch {
    return undefined;
  }
};
// #endregion BASE64 ENCODE

// #region DEEP COPY
/**
 * Deep copy function that creates a fully independent clone of an object.
 * It serializes the input object to a JSON string and then parses it to create a new object,
 * effectively breaking all references to the original object and its nested elements.
 * This ensures that changes to the copied object do not affect the original.
 *
 * @param {T} source The object to be deep-copied.
 * @returns {T} - A new object that is a deep copy of the input object and the same type.
 */
export const deepCopy = <T>(source: T): T => {
  return JSON.parse(JSON.stringify(source));
};
// #endregion DEEP COPY

// #region CHECK IF VALUE EXISTS
/**
 * Checks if the given value exists (is not null, undefined, or empty).
 *
 * @param value The value to check.
 * @returns `true` if the value exists, otherwise `false`.
 */
export const exists = (value: any): boolean => {
  if (value === null || value === undefined) return false;
  if (typeof value === 'string') return value.trim() !== '';
  if (Array.isArray(value)) return value.length > 0;
  if (typeof value === 'object') return Object.keys(value).length > 0;
  // \/ This covers booleans and numbers.
  return true;
};
// #endregion CHECK IF VALUE EXISTS

// #region TRANSLATE
import { type LocalizableString, Lang } from '@/types/form-fields-v2';

/**
 * Translates a text object based on the provided language.
 *
 * @param item The text object to translate.
 * @param lang The language in which to translate the text.
 * @returns The translated string or 'error' if translation fails.
 */
export const translate = (
  item: LocalizableString,
  lang: Lang | undefined
): string => {
  if (item) {
    if (typeof lang !== 'string') lang = Lang.en;
    if (typeof item === 'object' && item !== null)
      return item[lang!] || item[Lang.en] || 'error';
    else if (typeof item === 'string' && item.length) return item;
    else return 'error';
  } else return '';
};
// #endregion TRANSLATE

// #region PHONE NUMBER VALIDATION
import type { PhoneValidationResult, Profile } from '@/types/profile';
import { formatStringNumbersOnly } from '@/shared/formatters';

/**
 * Asynchronously validates a phone number and retrieves its status.
 * @param {string} phone - The phone number to validate.
 * @returns {Promise<PhoneValidationResult>}
A promise resolving to an object containing:
- `isValidPhoneNumber` (boolean): Whether the phone number is valid.
- `isSmsCapable` (boolean): Whether the phone number is SMS capable.
- `phoneType` (string): The type of the phone (e.g., mobile, landline).
 */
export const getPhoneStatus = async (
  phone: string
): Promise<PhoneValidationResult> => {
  const cleanPhone = formatStringNumbersOnly(phone);
  const apiUrl = `/api/Phone/ValidatePhoneNumber/?number=${cleanPhone}`;
  try {
    const res = await apiClient.get(apiUrl);
    return res.data;
  } catch (err) {
    console.error(`Error fetching from ${apiUrl}`, err);
    return {
      isValidPhoneNumber: false,
      isSmsCapable: false,
      phoneType: ''
    };
  }
};

/**
 * Calls into the BE to determine whether the given profile contains at least one valid phone number.
 * @param profile
 */
export const patientHasValidPhoneNumber = async (
  profile: Profile
): Promise<boolean> => {
  const phoneNumbers = [
    profile.homePhone,
    profile.mobilePhone,
    profile.businessPhone
  ];

  for (const phone of phoneNumbers) {
    if (phone == null || phone === undefined) {
      continue; // Skip null or undefined phone numbers
    }

    const result = await getPhoneStatus(phone);
    if (result?.isValidPhoneNumber) {
      return true; // Stop and return true as soon as a valid number is found
    }
  }

  return false; // Return false if no valid phone numbers are found
};
// #endregion PHONE NUMBER VALIDATION
