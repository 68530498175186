export const TimeSlotMode = {
  recurEdit: 'recurEdit',
  normal: 'normal'
};

/** Session key that represents the last URL visited */
export const SESSION_LASTURL = 'lastUrl';

/** Object map containing all hard-coded routes defined for the Vue Router */
export const ROUTES = {
  /** URL of route for not found page */
  NOT_FOUND: '/not-found',
  /** URL of route for unauthorized page */
  UNAUTHORIZED: '/unauthorized'
};

export const ProviderInvoiceReviewPayStep = {
  summary: 1,
  reviewExclusions: 2,
  makePayment: 3
};

export const ProviderDisputeReason = {
  disputeCharge: 'Dispute Charge',
  submitToInsurance: 'Submit to Insurance'
};

export const SEMEN_ANALYSIS_SESSION_KEY = 'se-kit';
export const SEMEN_ANALYSIS_APPT_TYPE = 'semenanalysis';

export const PhoneTypeValues = {
  cell: 'Cell',
  home: 'Home',
  business: 'Business'
};

export const BillingNotificationPreferences = {
  Sms: 'BillingNotification_TextSMS',
  Paper: 'BillingNotification_Paper',
  Email: 'BillingNotification_Email',
  SmsTandC: 'BillingNotification_TextSMS_TandC'
};
